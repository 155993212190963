<template>
    <main class="p-projects">
        <div class="filters links-underline container">
            <div class="filter link-underline" :class="{'link-active': selectedCategory === null}" @click="selectedCategory = null">{{ $t('projects.allProjects') }}</div>
            <div class="filter link-underline" :class="{'link-active': selectedCategory === 'residential'}" @click="selectedCategory = 'residential'">{{ $t('projects.categories.residential') }}</div>
            <div class="filter link-underline" :class="{'link-active': selectedCategory === 'commercial'}" @click="selectedCategory = 'commercial'">{{ $t('projects.categories.commercial') }}</div>
        </div>

        <div class="projects-wrapper">
            <div class="pager-wrapper">
                <div class="pager" v-if="filteredProjects">
                    <transition name="quick-fade" mode="out-in">
                        <span class="pager__current" :key="pagerCurrentProject">{{ pagerCurrentProject }}</span>
                    </transition>
                    <span class="pager__divider"></span>
                    <transition name="quick-fade" mode="out-in">
                        <span class="pager__total" :key="pagerProjectsCount">{{ pagerProjectsCount }}</span>
                    </transition>
                </div>
            </div>

            <transition-group name="fade" tag="div" class="container" v-if="filteredProjects">
                <div v-for="(project, index) in filteredProjects" :key="project.id" class="project" :data-index="index + 1">
                    <img :src="project.featuredImageUrl" alt="">
                    <div class="project-cta outline-cta">{{ $t('projects.discoverCta') }}<div class="outline-cta__arrow">
                            <svg height="22" viewBox="0 0 31 22" width="31" xmlns="http://www.w3.org/2000/svg"><path d="m200.75 9h3.5v23.9568345l8.75-8.8489208v4.5323741l-10.5 10.3597122-10.5-10.1438849v-4.7482014l8.75 8.8489208z" fill="none" stroke="#d8d8d8" stroke-width=".7" transform="matrix(0 -1 1 0 -8.5 213.5)"/></svg>
                        </div>
                    </div>
                    <div class="project__info">
                        <div class="project__info__title">{{ project.title }}</div>
                        <div class="project__info__date">{{ $t('projects.realised') + project.realisationDate }}</div>
                    </div>
                    <router-link :to="project.uri" class="project__overlay-cta"></router-link>
                    <div :to="project.uri" class="project__overlay"></div>
                </div>
            </transition-group>
        </div>

        <div class="cta-backtotop container" @click="scrollTop">{{ $t('projects.backToTop') }} <svg height="31" viewBox="0 0 22 31" width="22" xmlns="http://www.w3.org/2000/svg"><path d="m165.333333 1h3.333334v23.1582734l8.333333-8.5539569v4.381295l-10 10.0143885-10-9.8057554v-4.5899281l8.333333 8.5539569z" fill="none" stroke="#d8d8d8" transform="matrix(-1 0 0 -1 178 31)"/></svg></div>

        <Cta :type="'big'" :ctaText="$t('global.ctaContactUs')" :ctaUrl="$t('nav.contact.uri')" />
    </main>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Cta from '@/components/Cta';

export default {
    name: 'Projects',

    components: {
        Cta
    },

    data() {
        return {
            projects: null,
            selectedCategory: null,
            pagerCurrentProject: 1
        }
    },

    computed: {
        filteredProjects() {
            if (!this.selectedCategory) return this.projects;

            return this.projects.filter(e => {
                return e.category === this.selectedCategory
            });
        },

        pagerProjectsCount() {
            return this.filteredProjects ? this.filteredProjects.length : 0;
        }
    },

    watch: {
        filteredProjects() {
            this.$nextTick(() => {
                const stInstances = ScrollTrigger.getAll();
                stInstances.forEach((stInstance) => {
                    stInstance.kill();
                });

                this.initAnimations();
            });
        }
    },

    created() {
        this.$craft.getContentBySlug('projects')
            .then(res => {
                this.projects = res.data.projects;
                if (res.data.seo) {
                    this.$store.commit('SET_SEO', res.data.seo);
                }
            })
            .then(() => {
                this.initAnimations();
            })
            .then(() => {
                // Hardcoded slugs for this one because there's no Single in Craft for this one
                const channelHarcodedSlugs = {
                    'fr': '/projets',
                    'en': '/en/projects',
                }
                this.$store.commit('SET_OTHER_LOCALES_URLS', channelHarcodedSlugs);
                this.$store.commit('SET_DATA_READY', true);
            });
    },

    updated() {
        ScrollTrigger.refresh(true);
    },

    methods: {
        initAnimations() {
            gsap.utils.toArray('.project').forEach((project) => {
                const index = project.dataset.index;
                ScrollTrigger.create({
                    trigger: project,
                    start: 'top center',
                    end: 'bottom center',
                    onToggle: self => {
                        this.pagerCurrentProject = index;
                    }
                });

                const cta = project.querySelector('.project-cta');
                project.addEventListener('mousemove' , function(e){
                    let projectarea = project.getBoundingClientRect();
                    let posX = e.clientX - projectarea.left;
                    let posY = e.clientY - projectarea.top;

                    cta.style.left = posX - 100 + 'px';
                    cta.style.top = posY - 28 + 'px';
                })
            });

            ScrollTrigger.refresh(true);
        },

        scrollTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
}
</script>
